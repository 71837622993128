#ru #header .navbar-nav li {
    font-size: 0.9em;
    font-weight: 400;
}
#ru .info.team .tild {
    left: -110px;
    top: 310px;
}
#ru .info .services li p {
    font-weight: 400;
}
#ru .info .pricing .month {
    font-size: 1em;
    font-weight: 400;
}
#ru .info .more {
    font-weight: 400;
}

#ru .info.work .tild {
    top: 260px;
}
#ru .info .slider blockquote q {
    font-weight: 400;
}
#ru .info.team .more {
    font-size: 0.8em;
    font-weight: 400;
}

#ru .f1 .footer-txt .text p,
#ru .f1 .footer-txt #ru .f1 .contact,
#ru .f1 .contact address,
#ru .f1 .contact a,
#ru .f1 .contact span,
#ru #footer .copyright,
#ru #footer .sub-nav li a {
    font-weight: 100;
}
@media (max-width: 1279px) {
    #ru .info .txt-frame .img-holder h3 {
        font-size: 1em;
        font-weight: 600;
    }
    #ru .info .txt-frame .img-holder .designation {
        font-size: 0.7em;
        font-weight: 100;
        color: #444;
    }
}
@media (max-width: 1023px) {
    #ru .info.work .txt-frame .filter {
        font-weight: 100;
    }
    #ru .info .pricing .month {
        font-weight: 100;
        font-size: 0.7em;
        font-family: serif;
    }
}
@media (max-width: 767px) {
    #ru .info .slider blockquote .designation {
        font-weight: 100;
        margin-top: 10px;
    }
}
#ru .info .pricing .package {
    font-family: serif;
}
